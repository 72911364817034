export const extendedInputComponentStyles = {
  Input: {
    // baseStyle: {},
    // sizes: {},
    variants: {
      'customer-input': {
        field: {
          borderRadius: 0,
          border: '1px solid #b9b9b9',
          height: '45px',
          padding: '10px',
          fontFamily: 'Helvetica Neue',
        },
      },
    },
    // defaultProps: {},
  },
};
