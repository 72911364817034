// https://chakra-ui.com/docs/theming/customize-theme#customizing-single-components
// import { mode } from '@chakra-ui/theme-tools';

export const extendedLinkComponentStyles = {
  Link: {
    baseStyle: {
      fontWeight: 'bold',
    },
    sizes: {
      xl: {
        h: '56px',
        fontSize: 'lg',
        px: '32px',
      },
    },
    // variants: {
    //   primary: (props: any) => ({
    //     backgroundColor: 'primary.500',
    //     color: 'white',
    //     _hover: {
    //       backgroundColor: 'white',
    //       borderColor: 'primary.500',
    //       color: 'primary.500',
    //     },
    //   }),
    // },
    defaultProps: {
      size: 'md',
      // variant: 'primary-outline',
    },
  },
};
