// https://chakra-ui.com/docs/theming/component-style
// https://chakra-ui.com/docs/theming/customize-theme#customizing-single-components

import { theme as DefaultTheme } from '@chakra-ui/react';

import { extendedButtonComponentStyles } from './Button';
import { extendedLinkComponentStyles } from './Link';
import { extendedLabelComponentStyles } from './Label';
import { extendedInputComponentStyles } from './Input';
import { extendedHeadingComponentStyles } from './Heading';

export const extendedComponentStyles = {
  components: {
    ...DefaultTheme.components,
    ...extendedButtonComponentStyles,
    ...extendedLinkComponentStyles,
    ...extendedLabelComponentStyles,
    ...extendedInputComponentStyles,
    ...extendedHeadingComponentStyles,
  },
};
