import React from 'react';
import { useSession } from 'next-auth/react';
import type { AppProps } from 'next/app';
import { Spinner } from '@chakra-ui/react';
import { useRouter } from 'next/router';

import { AllProviders } from '../components/AllProviders';

import { LoggedInAdminLayout } from '@/layouts/LoggedInAdmin';
import { LoggedOutLayout } from '@/layouts/LoggedOut';
import { Role } from '@/types';
import { LoggedInPatientLayout } from '@/layouts/LoggedInPatient';

/**
 * Renders a layout depending on the result of the useSession hook
 */
function AppWithAuth({ children }) {
  const { data, status } = useSession();

  const router = useRouter();
  const isMasquerading = router.pathname === '/admin/quote/[id]';

  if (status === 'loading') {
    return <Spinner />;
  } else if (status !== 'authenticated') {
    return <LoggedOutLayout>{children}</LoggedOutLayout>;
  }

  return data.user.roles?.includes(Role.ADMIN) && !isMasquerading ? (
    <LoggedInAdminLayout>{children}</LoggedInAdminLayout>
  ) : (
    <LoggedInPatientLayout>{children}</LoggedInPatientLayout>
  );
}

function App({ Component, pageProps }: AppProps) {
  return (
    <AllProviders {...pageProps}>
      <AppWithAuth>
        <Component {...pageProps} />
      </AppWithAuth>
    </AllProviders>
  );
}

export default App;
