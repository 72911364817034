import React from 'react';
import { useSession } from 'next-auth/react';
import { ReactNode } from 'react';
import { useRouter } from 'next/router';

import { Role } from '@/types';

type Props = {
  /** The role required to see this content. */
  requiredRole?: Role;
  /** The content that is protected. */
  children: ReactNode;
};

/**
 * ProtectedContent
 * returns content if the user has the specified role
 * returns null otherwise
 */
export function ProtectedContent({ requiredRole = Role.ADMIN, children }: Props) {
  const { data } = useSession();
  const router = useRouter();
  const isMasquerading = router.pathname === '/admin/quote/[id]';

  const hasRole = data?.user?.roles?.includes(requiredRole) && !isMasquerading;

  if (!hasRole) return null;

  return <>{children}</>;
}
