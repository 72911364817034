// https://chakra-ui.com/docs/theming/customize-theme#scaling-out-your-project
import { extendTheme, ThemeConfig } from '@chakra-ui/react';

// import { extendedGlobalStyles } from './global';
import { extendedFoundations } from './foundations';
import { extendedComponentStyles } from './components';

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const overrides = {
  config,
  // ...extendedGlobalStyles,
  ...extendedFoundations,
  ...extendedComponentStyles,
};

export default extendTheme(overrides);
