import React, { ReactNode } from 'react';
import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import { ApolloProvider } from '@apollo/client';
import { SessionProvider } from 'next-auth/react';
import { Session } from 'next-auth';
import { OverlayProvider } from '@react-aria/overlays';

import { createApolloClient } from '../lib/apolloClient';
import defaultTheme from '../chakra';

import { Fonts } from './Fonts';

const defaultApolloClient = createApolloClient();

/**
 * Renders all context providers
 */
export function AllProviders({
  apolloClient = defaultApolloClient,
  theme = defaultTheme,
  session = null,
  children,
}) {
  return (
    <ApolloProvider client={apolloClient}>
      <ChakraProvider theme={theme}>
        <OverlayProvider>
          <Fonts />
          <SessionProvider session={session}>
            <CSSReset />

            {children}
          </SessionProvider>
        </OverlayProvider>
      </ChakraProvider>
    </ApolloProvider>
  );
}

export interface ProviderProps {
  apolloClient: typeof defaultApolloClient;
  theme: typeof defaultTheme;
  session: Session;
  children: ReactNode;
}
