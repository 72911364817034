import React from 'react';
import { Box, Flex, useMediaQuery, Link, Text, Button } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import NextLink from 'next/link';
import { FaArrowLeft } from 'react-icons/fa';

import { Logo } from '@/components/Logo';
import { Nav } from '@/components/Nav';
import { Footer } from '@/components/Footer';
import { useCreateStripePortalSessionMutation } from '@/types';

export function LoggedInPatientLayout({ children }) {
  const router = useRouter();
  const [isPrinted] = useMediaQuery('print');

  const [createStripePortalSession] = useCreateStripePortalSessionMutation();

  const goToStripePortal = async () => {
    const result = await createStripePortalSession();
    router.push(result.data.createStripePortalSession);
  };

  const quoteRoute = ['/patient/quotes/[id]', '/admin/quote/[id]'].includes(router.pathname);
  const patientQuoteRoute = router.pathname === '/patient/quotes/[id]';
  const maxWidth = quoteRoute ? '250px' : '576px';

  return (
    <Flex direction={['column', 'column', 'row']} minH="100vh">
      <Flex
        backgroundColor="primary.500"
        color="white"
        flexDirection="column"
        p={['32px 64px', '32px 64px', '64px']}
        justifyContent="space-between"
        w={['100%', '100%', '40%']}
        maxW={['100%', '100%', maxWidth]}
      >
        <Flex justifyContent="center" flexDirection="column">
          {patientQuoteRoute ? (
            <>
              <NextLink href="/" passHref>
                <Logo color="white" />
              </NextLink>
              <NextLink href="/patient">
                <Link display="flex" alignItems="center" mt="16" href="/patient" color="white">
                  <FaArrowLeft /> <Text ml={2}>Dashboard</Text>
                </Link>
              </NextLink>
              <Button
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                textAlign="left"
                mt={4}
                variant="link"
                color="white"
                onClick={goToStripePortal}
              >
                <Text ml={6}>Manage Billing</Text>
              </Button>
            </>
          ) : (
            <Logo color="white" />
          )}
        </Flex>
        <Box display={['none', 'none', 'block']}>
          <Footer />
        </Box>
      </Flex>

      <Box flexGrow={1} p={8}>
        <Flex justifyItems="flex-end" mb={10} display={isPrinted ? 'none' : 'flex'}>
          <Nav />
        </Flex>
        {children}
      </Box>
    </Flex>
  );
}
