// import { extendedBlurStyles } from "./blur";
// import { extendedBorderStyles } from "./borders";
// import { extendedBreakpoints } from "./breakpoints";
import { extendedColors } from './colors';
// import { extendedFonts } from "./fonts";
// import { extendedFontSizes } from "./fontSizes";
// import { extendedFontWeights } from "./fontWeights";
// import { extendedLetterSpacingStyles } from "./letterSpacing";
// import { extendedLineHeightStyles } from "./lineHeights";
// import { extendedRadiiStyles } from "./radii";
// import { extendedShadowsStyles } from "./shadows";
// import { extendedSpaceStyles } from "./space";
// import { extendedTextStyles } from "./textStyles";

// Extras https://chakra-ui.com/docs/features/text-and-layer-styles
// layout
// textStyles

export const extendedFoundations = {
  // ...extendedBlurStyles,
  // ...extendedBorderStyles,
  // ...extendedBreakpoints,
  ...extendedColors,
  // ...extendedFonts,
  // ...extendedFontSizes,
  // ...extendedFontWeights,
  // ...extendedLetterSpacingStyles,
  // ...extendedLineHeightStyles,
  // ...extendedRadiiStyles,
  // ...extendedShadowsStyles,
  // ...extendedSpaceStyles,
  // textStyles has its limits using Global as and Variants
  // ...extendedTextStyles,
};
