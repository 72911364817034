import { theme as DefaultTheme } from '@chakra-ui/react';

export const extendedColors = {
  colors: {
    ...DefaultTheme.colors,
    primary: {
      900: '#03272F',
      800: '#064E5F',
      700: '#09748E',
      600: '#0070CF',
      500: '#1970e2',
      400: '#3FCEF1',
      300: '#6FDAF4',
      200: '#9FE7F8',
      100: '#b9b9b9',
      50: '#EDF2F7',
    },
  },
};
