import React from 'react';
import { Box, Flex } from '@chakra-ui/react';

import { Logo } from '@/components/Logo';
import { Footer } from '@/components/Footer';

export function LoggedOutLayout({ children }) {
  return (
    <Flex direction="column" minH="100vh">
      <Flex p={4}>
        <Logo />
      </Flex>

      <Box mt={8}>{children}</Box>

      <Footer />
    </Flex>
  );
}
