import React from 'react';
import { Box, Flex } from '@chakra-ui/react';

import { Logo } from '@/components/Logo';
import { Nav } from '@/components/Nav';
import { Footer } from '@/components/Footer';

export function LoggedInAdminLayout({ children }) {
  return (
    <Flex direction="column" minH="100vh">
      <>
        <Flex p={4} alignItems="center" justifyContent="space-between">
          <Logo />
          <Flex>
            <Nav />
          </Flex>
        </Flex>
      </>

      <Box flex="1 1 auto" mt={8}>
        {children}
      </Box>

      <Footer />
    </Flex>
  );
}
