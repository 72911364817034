// https://chakra-ui.com/docs/theming/customize-theme#customizing-single-components
import { mode } from '@chakra-ui/theme-tools';

export const extendedButtonComponentStyles = {
  Button: {
    baseStyle: {
      fontWeight: 'bold',
    },
    sizes: {
      xl: {
        h: '56px',
        fontSize: 'lg',
        px: '32px',
      },
    },
    variants: {
      primary: () => ({
        backgroundColor: 'primary.500',
        color: 'white',
        borderWidth: 1,
        borderColor: 'white',
        _hover: {
          backgroundColor: 'white',
          borderColor: 'primary.500',
          borderWidth: 1,
          color: 'primary.500',
        },
      }),
      'customer-primary': {
        fontFamily: 'Campton',
        fontWeight: 500,
        backgroundColor: 'primary.600',
        color: 'white',
        borderWidth: 1,
        borderColor: 'white',
        borderRadius: 0,
        fontSize: '12px',
        textTransform: 'uppercase',
        letterSpacing: '.72px',
        _hover: {
          backgroundColor: 'white',
          borderColor: 'primary.600',
          borderWidth: 1,
          color: 'primary.600',
        },
        _focus: {
          color: 'white',
          fontWeight: 700,
        },
      },
      'customer-danger': {
        fontFamily: 'Campton',
        fontWeight: 500,
        backgroundColor: 'red.500',
        color: 'white',
        borderWidth: 1,
        borderColor: 'white',
        borderRadius: 0,
        fontSize: '12px',
        textTransform: 'uppercase',
        letterSpacing: '.72px',
        _hover: {
          backgroundColor: 'white',
          borderColor: 'red.600',
          borderWidth: 1,
          color: 'red.600',
        },
        _focus: {
          color: 'white',
          fontWeight: 700,
        },
      },
      // secondary: (props: any) => ({
      //   backgroundColor: mode('brand.secondary.700', 'brand.secondary.100')(props),
      //   color: mode('brand.secondary.100', 'brand.secondary.700')(props),
      //   _hover: {
      //     backgroundColor: mode('brand.secondary.300', 'brand.secondary.500')(props),
      //     color: mode('brand.secondary.500', 'brand.secondary.200')(props),
      //   },
      // }),
      'primary-outline': (props: any) => ({
        border: 'solid',
        borderWidth: '1px',
        borderColor: mode('primary.500', 'primary.500')(props),
        color: mode('primary.500', 'primary.500')(props),
        _hover: {
          backgroundColor: 'primary.500',
          color: 'white',
          borderColor: 'white',
        },
        _focus: {
          backgroundColor: 'primary.500',
          color: 'white',
          borderColor: 'white',
        },
        _disabled: {
          _hover: {
            borderColor: 'primary.500',
            color: 'primary.500',
          },
        },
      }),
      'customer-outline': (props: any) => ({
        border: 'solid',
        borderWidth: '1px',
        borderColor: mode('primary.500', 'primary.500')(props),
        borderRadius: 0,
        color: mode('primary.500', 'primary.500')(props),
        fontFamily: 'Campton',
        fontWeight: 500,
        textTransform: 'uppercase',
        fontSize: 12,
        letterSpacing: 0.72,
        height: '43px',
        pt: '5px',
        _hover: {
          color: 'white',
          backgroundColor: 'primary.500',
        },
        _focus: {
          color: 'white',
          backgroundColor: 'primary.500',
        },
      }),
      // 'secondary-outline': (props: any) => ({
      //   border: 'solid',
      //   borderWidth: '1px',
      //   borderColor: mode('brand.secondary.500', 'brand.secondary.700')(props),
      //   color: mode('brand.secondary.500', 'brand.secondary.700')(props),
      //   _hover: {
      //     backgroundColor: mode('brand.secondary.300', 'brand.secondary.500')(props),
      //     color: mode('brand.secondary.500', 'brand.secondary.200')(props),
      //     borderColor: mode('brand.secondary.300', 'brand.secondary.500')(props),
      //   },
      // }),
      danger: () => ({
        borderColor: 'red.500',
        borderWidth: 1,
        color: 'red.500',
        _hover: {
          backgroundColor: 'red.500',
          color: 'white',
        },
        _focus: {
          backgroundColor: 'red.500',
          color: 'white',
        },
        _disabled: {
          _hover: {
            borderColor: 'red.400',
            color: 'red.400',
          },
        },
      }),
      success: () => ({
        borderColor: 'green.500',
        borderWidth: 1,
        color: 'green.500',
        _hover: {
          backgroundColor: 'green.500',
          color: 'white',
        },
        _focus: {
          backgroundColor: 'green.500',
          color: 'white',
        },
        _disabled: {
          _hover: {
            borderColor: 'green.400',
            color: 'green.400',
          },
        },
      }),
    },
    defaultProps: {
      size: 'md',
      variant: 'primary-outline',
    },
  },
};
