// https://chakra-ui.com/docs/theming/customize-theme#customizing-single-components
// import { mode } from '@chakra-ui/theme-tools';

export const extendedLabelComponentStyles = {
  FormLabel: {
    // baseStyle: {},
    // sizes: {},
    variants: {
      'customer-label': {
        fontFamily: 'Campton',
        fontWeight: 200,
      },
    },
    // defaultProps: {},
  },
};
