// https://chakra-ui.com/docs/theming/customize-theme#customizing-single-components
// import { mode } from '@chakra-ui/theme-tools';

export const extendedHeadingComponentStyles = {
  Heading: {
    baseStyle: {},
    sizes: {},
    variants: {
      'customer-heading': {
        color: 'primary.500',
        fontFamily: 'Campton',
        margin: 0,
      },
    },
    defaultProps: {
      variant: 'primary-heading',
      // size: 'xl',
    },
  },
};
