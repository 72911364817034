import React from 'react';
import { Global } from '@emotion/react';

export const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Campton';
        font-style: light;
        font-weight: 300;
        src: url('/fonts/Campton-Light.otf') format('opentype');
      }
      @font-face {
        font-family: 'Campton';
        font-style: medium;
        font-weight: 500;
        src: url('/fonts/Campton-Medium.otf') format('opentype');
      }
      @font-face {
        font-family: 'Campton';
        font-style: bold;
        font-weight: 700;
        src: url('/fonts/Campton-Bold.otf') format('opentype');
      }
    `}
  />
);
