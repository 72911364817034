import React from 'react';
import { Image, VStack, Heading } from '@chakra-ui/react';

export interface LogoProps {
  type?: string;
  color?: string;
}

export function Logo({ type, color }: LogoProps) {
  return (
    <VStack>
      {type === 'icon' ? (
        <Image src="/logo.png" w="40px" cursor="pointer" borderRadius="100%" />
      ) : color === 'white' ? (
        <>
          <Image src="/cny-logo-white.png" w="220px" cursor="pointer" ml={2} />
          <Heading
            fontFamily="Campton"
            fontWeight={500}
            letterSpacing={0.72}
            as="a"
            fontSize="15px"
            cursor="pointer"
          >
            Fertile Financing Portal
          </Heading>
        </>
      ) : (
        <>
          <Image src="/CNY-logo.png" w="200px" cursor="pointer" />
          <Heading
            fontFamily="Campton"
            fontWeight={500}
            letterSpacing={0.72}
            as="a"
            size="xs"
            pl="45px"
            cursor="pointer"
          >
            Fertile Financing Portal
          </Heading>
        </>
      )}
    </VStack>
  );
}
